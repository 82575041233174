import store from "@/state/store";

export default [
  // FieldMR Dashboard 
  {
    path: "/fieldmr/dashboard",
    name: "Dashboard",
    meta: {
      authRequired: false,
    },
    component: () => import("../views/pages/fieldMrDashboard/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/digimr/analytics",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/analytics/index"),
  },
  {
    path: "/digimr/telecaller-analytics",
    name: "Telecaller analytics",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/digimr/analytics/main-page"),
  },
  {
    path: "/digimr/employee-performance",
    name: "Employee Performance",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/digimr/employee-performance/main-page"),
  },
  {
    path: "/digimr/telecaller-histories",
    name: "Telecaller Histories",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/digimr/Histories/main-comp"),
  },
  {
    path: "/digimr/best-call-status",
    name: "Best Call Status",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/digimr/best-call-status/main-comp"),
  },

// Field Mr Routes

  {
    path: "/fieldmr/referralSummary",
    name: "Referral Summary",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/fieldmr/main.vue"),
  },
  {
    path: "/fieldmr/summary",
    name: "Summary",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/fieldmr/analytics/main-page.vue"),
  },
  // {
  //   path: "/digimr/client/telecaller-histories",
  //   name: "Telecaller Histories",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/digimr/client/main-comp"),
  // },
  {
    path: "/users/registrations-summary",
    name: "User Registrations",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/live-registration/index"),
  },
  {
    path: "/users/registrants",
    name: "Registrants",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/live-registration/Registrants/index"),
  },
  {
    path: "/users/registrations-digimrvfieldmr",
    name: "User Registrations - DIGI MR vs Field MR",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/live-registration/DigiMRvFieldMR/index"),
  },
  {
    path: "/users/registrations-digimr",
    name: "User Registrations - DIGI MR",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/live-registration/DigiMR/index"),
  },
  {
    path: "/users/registrations-fieldmr",
    name: "User Registrations - Field MR",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/live-registration/FieldMR/index"),
  },
  {
    path: "/content_analytics/articles/overall",
    name: "Articles",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/articles/index"),
  },
  {
    path: "/content_analytics/articles/top-viewers",
    name: "Articles",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/articles/top-viewers/index"),
  },
  {
    path: "/content_analytics/live-events/summary",
    name: "Live Events",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/live-events/index"),
  },
  {
    path: "/content_analytics/live-events/registrants",
    name: "Live Event Registrants",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/live-events/registrants"),
  },
  {
    path: "/content_analytics/videos/overall",
    name: "Videos",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/videos/index"),
  },
  {
    path: "/content_analytics/newsletters/overall",
    name: "Newsletters",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/newsletters/index"),
  },
  {
    path: "/content_analytics/newsletters/top-viewers",
    name: "Newsletters",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/newsletters/top-viewers/index"),
  },
  {
    path: "/content_analytics/cases/overall",
    name: "Cases",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cases/index"),
  },
  {
    path: "/prime_user/overall-report",
    name: "Prime User",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/prime/index"),
  },
  {
    path: "/prime_user/view_rewards/",
    name: "View_Rewards",
    props: true,
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/prime/viewRewards"),
  },
  {
    path: "/forum_analytics/overall/",
    name: "Forum_Analytics",
    props: true,
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum/index"),
  },
  {
    path: "/content_analytics/retention_analysis",
    name: "Retention Analysis",
    props: true,
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/Reports/retention"),
  },



    // Routes Without Authentication
  {
    path: "/open_fieldmr/referralSummary",
    name: "Referral Summary",
    meta: {
      authRequired: false,
    },
    component: () => import("../views/pages/fieldmr/main.vue"),
  }
];
