import Vue from "vue";
import App from "./App.vue";
import JsonExcel from "vue-json-excel";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";
import { authHeader } from "./helpers/auth/auth-header";
import { TabPlugin } from '@syncfusion/ej2-vue-navigations';
import Vuex from "vuex";
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Vuex);
Vue.use(TabPlugin);
Vue.use(ElementUI, { locale });

// import JsonExcel from "vue-json-excel"
//
// Vue.component("downloadExcel", JsonExcel);

import vco from "v-click-outside";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";

import axios from "axios";
axios.defaults.headers = authHeader();
Vue.prototype.$http = axios;

import "@/assets/scss/app.scss";

// import { initFirebaseBackend } from './helpers/firebase/authUtils';
//
// import { configureBackend } from './helpers/auth/backend';
//
// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else {
//   configureBackend();
// }
// const storeData =  new Vuex.Store({
//   state: { token : null },
//   mutations: {
//     setToken(state, token) {
//       state.token = token;
//     },
//   },
//   actions: {},
//   modules: {},
// });
Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.component("downloadExcel", JsonExcel);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});
Vue.component("apexchart", VueApexCharts);

new Vue({
  data: { loading: false },
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
