import appConfig from "@/app.config";
import store from "@/state/store";

export const userService = {
  login,
  logout,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(appConfig.api_login_url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      if (res.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        store.commit("setToken", res.data.token);
        store.commit("setRole", res.data.role)
        store.commit("setPermissions", res.data.permissions)
        localStorage.setItem("user", JSON.stringify(res.data));
      }
      return res.data;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
